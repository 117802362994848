import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Header, Dimmer, Loader, Image } from 'semantic-ui-react'
import history from '../../../../history'

import { loadCheckoutData, updateConsumerData } from '../../../../actions'
import danger from '../../../../assets/signe-de-danger.png'

import ErrorBoundary from '../../../layout/ErrorBoundary'

import { trackEvent } from '../../../../utils/trackEvent'
import { mapItems } from './helpers/mapItems'
import { mapStateToProps } from './helpers/mapStateToProps'
import { StepGroup } from './StepGroup'
import CheckoutCartInfo from './CheckoutCartInfo'
import { CheckoutDeliveryInfo } from './CheckoutDeliveryInfo'
import { PersistentCheckoutBar } from './PersistentCheckoutBar'

import Styles from './styles.module.css'

const CheckoutOrderConfirmationBase = ({
    cartItems,
    amountToPay,
    addedCharges,
    currentLocale,
    livdomFee,
    creditUsed,
    currentUser,
    updateParams,
    currentGlobalOrder,
    ready,
    loading,
    redirectToAccount,
    orderreservation_id,
    amountTotal,
    welcomeCredit,
    loadCheckoutData,
    cart_globalorder_id,
}) => {
    const [loadedCheckoutData, setLoadedCheckoutData] = useState(false)
    const [salesExpiry, setSalesExpiry] = useState(null)
    const [livdomSchedule, setLivdomSchedule] = useState(null)
    const [livdomDeliveryRange, setLivdomDeliveryRange] = useState(livdomSchedule ? livdomSchedule[0].value : null)
    const [door, setDoor] = useState(currentUser?.door || false);

    useEffect(() => {
        let isMounted = true; // Prevent state updates if component unmounts

        const fetchCheckoutData = async () => {
            try {
                await loadCheckoutData({
                    globalorder_id: cart_globalorder_id,
                    orderreservation_id: orderreservation_id,
                });
                if (isMounted) {
                    setLoadedCheckoutData(true);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchCheckoutData();

        return () => {
            isMounted = false; // Cleanup
        };
    }, [cart_globalorder_id, orderreservation_id, loadCheckoutData]);

    useEffect(() => {
        if (currentGlobalOrder && currentGlobalOrder.order_end) {
            const now = new Date();
            const expiry = new Date(currentGlobalOrder.order_end);
            setSalesExpiry(Math.max(Math.round((expiry - now) / 1000 / 60), 0));
        }
    }, [currentGlobalOrder]);

    useEffect(() => {
        if (currentLocale.code === 'DOM' && currentGlobalOrder?.locale_distribution_hours && currentGlobalOrder?.distribution_date) {
            let myLivdomSchedule = [];
            let n = 0;

            currentGlobalOrder.locale_distribution_hours.split(' ').forEach(s => {
                if (s.length > 3) {
                    let [scheduleStart, scheduleEnd] = s.split('-');
                    let scheduleStartDt = new Date(currentGlobalOrder.distribution_date);
                    let scheduleEndDt = new Date(currentGlobalOrder.distribution_date);

                    scheduleStartDt.setHours(Number(scheduleStart.split('h')[0]), Number(scheduleStart.split('h')[1]) || 0, 0, 0);
                    scheduleEndDt.setHours(Number(scheduleEnd.split('h')[0]), Number(scheduleEnd.split('h')[1]) || 0, 0, 0);

                    myLivdomSchedule.push({
                        key: n,
                        text: s,
                        value: { start: scheduleStartDt, end: scheduleEndDt },
                    });
                    n++;
                }
            });

            setLivdomSchedule(myLivdomSchedule);
        }
    }, [currentLocale, currentGlobalOrder]);

    useEffect(() => {
        if (currentUser?.door !== undefined) {
            setDoor(currentUser.door);
        }
    }, [currentUser]);

    const handleSubmit = () => {
        let updatedUser = { door, livdomDeliveryRange }

        console.log('updatedUser', updatedUser)

        // validate the form
        return updateParams(updatedUser)
        // .then(() => {
        //     history.push('/checkout/reglement')
        //     window.location.reload()
        // })
    }

    if (!cartItems || cartItems.length === 0) {
        return <Redirect to="/" />
    }

    if (!ready || loading || !loadedCheckoutData) {
        return (
            <Dimmer active inverted style={{ height: '300px' }}>
                <Loader inverted content="Chargement" />
            </Dimmer>
        )
    }

    if (redirectToAccount) {
        window.dataLayer.push({
            event: 'home-delivery-checkout-invalid-address',
        })

        return <Redirect to="/account/details?invalidDeliveryAddress=true" />
    }

    window.dataLayer &&
        window.dataLayer.push({
            event: 'begin_checkout',
            eventProps: {
                currency: 'EUR',
                value: cartItems
                    .map(item => mapItems('gtm', item))
                    .reduce((a, c) => a + c.item_price * c.item_quantity, 0),
                items: cartItems.map(item => mapItems('gtm', item)),
            },
        })

    trackEvent({
        _id: currentUser._id,
        event: 'Started Checkout',
        data: {
            $event_id: orderreservation_id + '_' + new Date().getTime(),
            $value: amountTotal / 100,
            Items: cartItems.map(item => mapItems('klaviyo', item)),
        },
    })

    return (
        <div
            data-testid="checkout-order-confirmation-container"
            style={window.innerWidth < 500 ? { paddingBottom: '100px' } : {}}
        >
            <div className={Styles.Table}>
                {salesExpiry === 0 ? (
                    <div className={Styles.dangerContainer}>
                        <Image
                            src={danger}
                            alt="attention"
                            style={{ width: '40px', height: '40px' }}
                        />
                        <p
                            style={{
                                fontSize: '0.9em',
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}
                        >
                            Cette vente est{' '}
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.1em',
                                }}
                            >
                                clôturée
                            </span>
                            <br />
                            Nous vous invitons à sélectionner une autre vente
                            pour passer votre commande
                        </p>
                    </div>
                ) : salesExpiry <= 60 ? (
                    <div className={Styles.dangerContainer}>
                        <Image
                            src={danger}
                            alt="attention"
                            style={{ width: '40px', height: '40px' }}
                        />
                        <p
                            style={{
                                fontSize: '0.9em',
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}
                        >
                            La vente se clôture dans{' '}
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.1em',
                                }}
                            >
                                {salesExpiry} min
                            </span>
                            <br />
                            Passé ce délai, la validation de votre commande ne
                            sera pas prise en compte
                        </p>
                    </div>
                ) : null}
                <StepGroup />

                <div className={Styles.DeliveryInfoContainer}>
                    <Header
                        as="h2"
                        style={{ marginBottom: '2em' }}
                        content="Pour récupérer vos produits"
                        className={Styles.Header}
                    />
                    <CheckoutDeliveryInfo
                        currentLocale={currentLocale}
                        updateParams={updateParams}
                        currentUser={currentUser}
                        currentGlobalOrder={currentGlobalOrder}
                        livdomDeliveryRange={livdomDeliveryRange}
                        setLivdomDeliveryRange={setLivdomDeliveryRange}
                        livdomSchedule={livdomSchedule}
                    />
                </div>

                <div className={Styles.CartInfoContainer}>
                    <CheckoutCartInfo
                        currentLocale={currentLocale}
                        cartItems={cartItems}
                        amountToPay={amountToPay}
                        addedCharges={addedCharges}
                        creditUsed={creditUsed}
                        livdomFee={livdomFee}
                        welcomeCredit={welcomeCredit}
                    />
                </div>
            </div>

            <PersistentCheckoutBar
                door={door}
                setDoor={setDoor}
                currentUser={currentUser}
                currentLocale={currentLocale}
                handleSubmit={handleSubmit}
            />
        </div>
    )
}

const CheckoutOrderConfirmation = (props) => (
    <ErrorBoundary page="checkout-order-confirmation">
        <CheckoutOrderConfirmationBase {...props} />
    </ErrorBoundary>
)

const mapDispatchToProps = dispatch => {
    return {
        loadCheckoutData: params => dispatch(loadCheckoutData(params)),
        updateParams: params => dispatch(updateConsumerData(params)),
    }
}

export { CheckoutOrderConfirmationBase }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutOrderConfirmation)